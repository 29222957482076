<template>
    <main class="login">
        <div class="login___wrapper">
            <div class="w-full max-lg:container">
                <div class="w-full lg:px-20">
                    <h1 class="h1 mb-4 sr-only">Apollo EMS</h1>

                    <div class="mb-8">
                        <organisation-logo logo-class="w-full max-w-48" />
                    </div>

                    <slot />
                </div>
            </div>
        </div>

        <div class="login__graphic">
            <img
                v-if="tenantConfig?.empCoverImage"
                :src="tenantConfig.empCoverImage.url"
                :alt="tenantConfig.name"
                loading="lazy"
                class="block absolute top-0 left-0 w-full h-full object-cover object-center"
            />
        </div>
    </main>
</template>

<script setup lang="ts">
    import type { TenantType } from '~/generated/types';

    useHead({
        htmlAttrs: {
            lang: 'en',
            class: 'login-page',
        },
    });

    const tenantConfig = useState<TenantType | undefined>('tenantConfig');
</script>

<style lang="postcss">
    html.login-page,
    html.login-page body {
        @apply m-0 p-0 min-h-screen;
    }

    .login {
        @apply flex h-screen;

        &___wrapper {
            @apply flex items-center w-full bg-body text-gray-900;
            @apply dark:text-gray-100;
            @apply dark:bg-gray-900;

            @screen lg {
                @apply relative z-10;
                width: clamp(30rem, 33.33%, 40rem);
                box-shadow: 0 25px 50px -12px rgb(0 0 0 / 1);
            }
        }

        &__graphic {
            @apply hidden lg:block flex-1 bg-gradient-to-br from-[#182C3F] to-[#375C7B];
        }
    }
</style>
